import { useEffect } from "react";

function Initiate() {
  useEffect(function () {
    const searchParams = new URLSearchParams(document.location.search);
    // console.log(searchParams.get("identifier"));
    let identifier = searchParams.get("identifier");

    document.location = `microsoft-edge:https://link-kyc.idv.hyperverge.co/?identifier=${identifier}`;
  }, []);
}

export default function App() {
  return Initiate();
}
